import '../App.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import logo from '../img/logo.svg';
import tg from '../img/tg.svg';

function Header({ onClickTg }) {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });
  const isMobile = useMediaQuery({ query: '(max-width: 719px)' });

  return (
    <div className={`${isMobile ? 'logo-mobile' : 'logo'} ml-2 mt-1`}>
      <img
        src={logo}
        alt="logo"
        height={`${isMobile ? '65%' : isDesktop ? 60 : 50}`}
        width={`${isMobile ? '65%' : isDesktop ? 170 : 140}`}
      />
    </div>
  );
}

export default Header;
