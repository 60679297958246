import './App.css';
import MediaQuery from 'react-responsive';
import { useEffect, useState } from 'react';

import Footer from './components/footer';
import Header from './components/header';
import CustomSection from './components/section';
import Mobile from './views/home/Mobile';
import Desktop from './views/home/Desktop';
import Tablet from './views/home/Tablet';
import { AnchorProvider } from 'react-anchor-navigation';

function Home({ config }) {
  const uagent = navigator.userAgent.toLowerCase();

  const [OS, setOS] = useState('');

  const props = {
    onClickIos: () => onClick(process.env.REACT_APP_APPLE),
    onClickGoogle: () => onClick(process.env.REACT_APP_GOOGLE),
    onClickHuawei: () => onClick(process.env.REACT_APP_HUAWEI),
    onClickRuStore: () => onClick(process.env.REACT_APP_RUSTORE),
  };

  const detectIphone = () => {
    console.log('uagent', uagent);
    if (/iphone|ipad/i.test(uagent)) {
      setOS('ios');
      return;
    }
    if (/android/i.test(uagent)) {
      setOS('android');
      return;
    }
    setOS('desktop');
  };

  useEffect(() => {
    detectIphone();
    // console.log('config:', config);
  }, [config]);

  const onClick = (url) => {
    /*Linking.canOpenURL(url).then((supported) => {
      return Linking.openURL(url);
    }); */
    window.open(url, '_self', false);
  };

  return (
    <AnchorProvider>
      <div className="App">
        <Header />
        <MediaQuery minWidth={1280}>
          <Desktop os={OS} {...props} />
        </MediaQuery>
        <MediaQuery maxWidth={1279} minWidth={720}>
          <Tablet os={OS} {...props} />
        </MediaQuery>
        <MediaQuery maxWidth={719}>
          <Mobile os={OS} {...props} />
        </MediaQuery>
        <CustomSection id="contacts">
          <Footer onClickTg={() => onClick(process.env.REACT_APP_TELEGRAM)} config={config} />
        </CustomSection>
      </div>
    </AnchorProvider>
  );
}

export default Home;
