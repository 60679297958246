import './App.css';
import { useEffect, useRef, useState } from 'react';
import Footer from './components/footer';
import Header from './components/header';
import { useNavigate } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import logo from './img/logo.svg';
import arrow from './img/Arrow.svg';

function Politic({ link }) {
  const onClick = (url) => {
    window.open(url, '_self', false);
  };

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const isMobile = useMediaQuery({ query: '(max-width: 719px)' });

  const [html, setHtml] = useState('');

  const mount = useRef(null);
  useEffect(() => {
    if (!mount.current && link) {
      fetch(link)
        .then((res) => res.json())
        .then((res) => {
          // console.log('resPol:', res);
          setHtml(Object.values(res)[0]);
        });
      mount.current = true;
    } else if (html !== '' && link) {
      fetch(link)
        .then((res) => res.json())
        .then((res) => {
          // console.log('resPol:', res);
          setHtml(Object.values(res)[0]);
        });
    }
    // console.log('link:', link);
  }, [link]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [html]);

  return (
    <div className="App">
      <Header />
      <div className={`ml-2 mr-2 `}>
        <button
          className={`back-button mb-1 ${isMobile ? 'back-mobile' : 'mt-2'}`}
          onClick={() => navigate('/')}
        >
          <div className="row-button-back">
            <img src={arrow} height="100%" width="12%" />
            <MediaQuery minWidth={720}>
              <span className="back-title ml-1">Вернуться назад</span>
            </MediaQuery>
          </div>
        </button>
        <div
          className={`html ${!isDesktop ? 'html-mobile' : ''}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <Footer onClickTg={() => onClick('https://t.me/khmelnaia_milia')} />
    </div>
  );
}

export default Politic;
