import './Tablet.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import iosButton from '../../img/ios-button.svg';
import googleButton from '../../img/google-button.svg';
import huaweiButton from '../../img/huawei-button.svg';
import rustoreButton from '../../img/rustore-button.svg';

import { ReactComponent as IPhone } from '../../img/iphone14.svg';
import logo from '../../img/logo.svg';
import { mockup, mockup2_tablet as mockup2, mockup3, mockup4 } from '../../components/mockups.js';
import elipse from '../../img/ellipse.svg';
import elipse2 from '../../img/ellipse-2.svg';
import tg from '../../img/tg.svg';

function Tablet({ onClickIos, onClickGoogle, onClickHuawei, onClickRuStore, os, onClickTg }) {
  return (
    <div className="App-header">
      <div className="row-container">
        <div className="flex3 ml-2 mt-1">
          <div className="left-container">
            <span className="melper melper-tablet">Melper</span>
            <p className="title-app-tablet">Приложение для<br />мониторинга товаров<br />на маркетплейсах</p>
            <div className="Buttons-container">
              <div className="Buttons">
                {os == 'ios' && (
                  <img className="Button" src={iosButton} alt="ios Button" onClick={onClickIos} />
                )}
                {(os == 'android' || os == 'desktop') && (
                  <>
                    <img
                      className="Button"
                      src={googleButton}
                      alt="google Button"
                      onClick={onClickGoogle}
                    />
                    <img
                      className="Button"
                      src={huaweiButton}
                      alt="huawei Button"
                      onClick={onClickHuawei}
                    />
                  </>
                )}
              </div>
              <div className="Buttons">
                {(os == 'android' || os == 'desktop') && (
                  <img
                    className="Button Button-slim"
                    src={rustoreButton}
                    alt="rustore Button"
                    onClick={onClickRuStore}
                  />
                )}
                {os == 'desktop' && (
                  <img
                    className="Button Button-slim"
                    src={iosButton}
                    alt="ios Button"
                    onClick={onClickIos}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex2">
          <img className="elipse" src={elipse} alt="elipse" width="70%" height="70%" />

          <img className="absolute-mockup" src={mockup} alt="logo" width="100%"  />
        </div>
      </div>
      <div className="flex1-mobile">
        <div className="mobile-container mb-2">
          <span className="title-item-table">Ищите товар по артикулу<br />и названию</span>
          <span className="context-item-table">
            Для поиска достаточно ввести название товара и артикул.<br />Далее наша система сама найдет
            нужный товар и покажет<br />его позицию в выдаче.
          </span>
        </div>
        <img className="elipse2-tablet" src={elipse2} alt="elipse" width="35%" height="35%" />

        <img className="mockup2-tablet" src={mockup2} width="30%" />
      </div>
      <div className="flex1-mobile mt-1">
        <div className="mobile-container">
          <span className="title-item-table">Или просто вставьте ссылку</span>
          <span className="context-item-table">
            А если у вас есть ссылка на товар в выбранном маркетплейсе -<br />вы можете просто вставить
            ее в соответствующее поле<br />в мобильном приложении.
          </span>
        </div>
      </div>
      <div className="flex1-mobile mt-1">
        <img className="elipse3-mobile" src={elipse} alt="elipse" height="50%" width="50%" />

        <img className="mockup3-tablet" src={mockup3} width="30%" />
      </div>

      <div className="flex1-mobile mt-3">
        <span className="fm-context-tablet">
          <span className="red">Быстрый</span> мониторинг ваших<br />товаров, который всегда<br />у вас 
          <span className="blue"> в кармане</span>
        </span>
      </div>
      <div className="row-container mt-2">
        <div className="flex1">
          <div className="button-box-tablet">
            {(os == 'desktop' || os == 'ios') && (
              <img className="Button" src={iosButton} alt="ios Button" onClick={onClickIos} />
            )}
            {(os == 'android' || os == 'desktop') && (
              <>
                <img
                  className="Button"
                  src={rustoreButton}
                  alt="rustore Button"
                  onClick={onClickRuStore}
                />
                <img
                  className="Button"
                  src={googleButton}
                  alt="google Button"
                  onClick={onClickGoogle}
                />
                <img
                  className="Button"
                  src={huaweiButton}
                  alt="huawei Button"
                  onClick={onClickHuawei}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex1">
          <img className="absolute-mockup4" src={mockup4}  width="80%" />
        </div>
      </div>
    </div>
  );
}

export default Tablet;
