import './Mobile.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import iosButton from '../../img/ios-button.svg';
import googleButton from '../../img/google-button.svg';
import huaweiButton from '../../img/huawei-button.svg';
import rustoreButton from '../../img/rustore-button.svg';
import Footer from '../../components/footer';
import { ReactComponent as IPhone } from '../../img/iphone14.svg';
import logo from '../../img/logo.svg';
import { mockup, mockup2_tablet as mockup2, mockup3, mockup4 } from '../../components/mockups.js';
import elipse from '../../img/ellipse.svg';
import elipse2 from '../../img/ellipse-2.svg';
import tg from '../../img/tg.svg';

function Mobile({ onClickIos, onClickGoogle, onClickHuawei, onClickRuStore, os, onClickTg }) {
  return (
    <div className="App-header">
      <div className="column-container mt-2">
        <div className="flex1-mobile">
          <img className="elipse-mobile" src={elipse} alt="elipse" width="90%" height="90%" />
          <img className="absolute-mockup" src={mockup} alt="logo" width="45%" height="45%" />
        </div>
        <div className="flex1-mobile">
          <div className="mobile-container">
            <span className="melper-mobile ">Melper</span>
            <p className="title-app-mobile">
              Приложение для
              <br />
              мониторинга товаров
              <br />
              на маркетплейсах
            </p>
            <div className="Buttons-container-mobile">
              {os == 'ios' && (
                <img
                  className="Button-mobile Button-wide-mobile"
                  src={iosButton}
                  alt="ios Button"
                  onClick={onClickIos}
                />
              )}
              {(os == 'android' || os == 'desktop') && (
                <>
                  <div className="Buttons-mobile">
                    <img
                      className="Button-mobile"
                      src={googleButton}
                      alt="google Button"
                      onClick={onClickGoogle}
                    />
                    <img
                      className="Button-mobile"
                      src={huaweiButton}
                      alt="huawei Button"
                      onClick={onClickHuawei}
                    />
                  </div>
                  <div className="Buttons-mobile">
                    <img
                      className="Button-mobile Button-slim-mobile"
                      src={rustoreButton}
                      alt="rustore Button"
                      onClick={onClickRuStore}
                    />
                    {os == 'desktop' && (
                      <img
                        className="Button-mobile Button-slim-mobile "
                        src={iosButton}
                        alt="ios Button"
                        onClick={onClickIos}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex1-mobile">
        <div className="mobile-container mb-2">
          <span className="title-item-mobile">Ищите товар по артикулу и названию</span>
          <span className="context-item-mobile">
            Для поиска достаточно ввести название товара и артикул.
            <br />
            Далее наша система сама
            <br />
            найдет нужный товар и покажет
            <br />
            его позицию в выдаче.
          </span>
        </div>
        <img className="elipse2-mobile" src={elipse2} alt="elipse" width="45%" height="45%" />

        <img className="mockup2-mobile" src={mockup2} width="50%" />
      </div>
      <div className="flex1-mobile mt-1">
        <div className="mobile-container">
          <span className="title-item-mobile">Или просто вставьте ссылку</span>
          <span className="context-item-mobile">
            А если у вас есть ссылка
            <br />
            на товар
            <br />в выбранном маркетплейсе -<br />
            вы можете просто вставить ее
            <br />в соответствующее поле
            <br />в мобильном приложении.
          </span>
        </div>
      </div>
      <div className="flex1-mobile mt-1">
        <img className="elipse3-mobile" src={elipse} alt="elipse" height="50%" width="50%" />

        <img className="mockup3-mobile" src={mockup3} width="55%" />
      </div>

      <div className="flex1-mobile mt-3">
        <span className="fm-context-mobile">
          <span className="red">Быстрый</span>
          <br />
          мониторинг ваших
          <br />
          товаров, который
          <br />
          всегда
          <br />у вас
          <span className="blue"> в кармане</span>
        </span>
      </div>
      <div className="flex1-mobile mt-2">
        <img className="mockup4-mobile" src={mockup4} height="100%" width="70%" />
        <div className="mobile-container">
          <div className="Buttons-container-mobile">
            {os == 'ios' && (
              <img
                className="Button-mobile Button-wide-mobile"
                src={iosButton}
                alt="ios Button"
                onClick={onClickIos}
              />
            )}
            {(os == 'android' || os == 'desktop') && (
              <>
                <div className="Buttons-mobile">
                  <img
                    className="Button-mobile"
                    src={googleButton}
                    alt="google Button"
                    onClick={onClickGoogle}
                  />
                  <img
                    className="Button-mobile"
                    src={huaweiButton}
                    alt="huawei Button"
                    onClick={onClickHuawei}
                  />
                </div>
                <div className="Buttons-mobile">
                  {os == 'desktop' && (
                    <img
                      className="Button-mobile Button-slim-mobile "
                      src={iosButton}
                      alt="ios Button"
                      onClick={onClickIos}
                    />
                  )}
                  <img
                    className="Button-mobile Button-slim-mobile"
                    src={rustoreButton}
                    alt="rustore Button"
                    onClick={onClickRuStore}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobile;
