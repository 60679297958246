import '../../App.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import iosButton from '../../img/ios-button.svg';
import googleButton from '../../img/google-button.svg';
import huaweiButton from '../../img/huawei-button.svg';
import rustoreButton from '../../img/rustore-button.svg';

import { ReactComponent as IPhone } from '../../img/iphone14.svg';
import logo from '../../img/logo.svg';
import { mockup, mockup2, mockup3, mockup4 } from '../../components/mockups.js';
import elipse from '../../img/ellipse.svg';

import elipse2 from '../../img/ellipse-2.svg';

import tg from '../../img/tg.svg';

function Desktop({ onClickIos, onClickGoogle, onClickHuawei, onClickRuStore, os, onClickTg }) {
  return (
    <div className="App-header">
      <div className="row-container ml-2">
        <div className="flex3 mt-1">
          <span className="melper melper-desktop">Melper</span>
          <p className="title-app">Приложение для мониторинга<br />товаров на маркетплейсах</p>
          <div className="Buttons-container">
            <div className="Buttons">
              {os == 'ios' && (
                <img className="Button" src={iosButton} alt="ios Button" onClick={onClickIos} />
              )}
              {(os == 'android' || os == 'desktop') && (
                <>
                  <img
                    className="Button"
                    src={googleButton}
                    alt="google Button"
                    onClick={onClickGoogle}
                  />
                  <img
                    className="Button"
                    src={huaweiButton}
                    alt="huawei Button"
                    onClick={onClickHuawei}
                  />
                </>
              )}
            </div>
            <div className="Buttons">
              {(os == 'android' || os == 'desktop') && (
                <img
                  className="Button Button-slim"
                  src={rustoreButton}
                  alt="rustore Button"
                  onClick={onClickRuStore}
                />
              )}
              {os == 'desktop' && (
                <img className="Button Button-slim" src={iosButton} alt="ios Button" onClick={onClickIos} />
              )}
            </div>
          </div>
        </div>
        <div className="flex2">
          <img className="elipse" src={elipse} alt="elipse" width="70%" height="70%" />

          <img className="absolute-mockup" src={mockup} alt="logo" width="90%"  />
        </div>
      </div>
      <div className="row-container ml-2 mt-1 ">
        <div className="flex2">
          <img className="elipse2" src={elipse2} alt="elipse" width="60%" height="60%" />

          <img className="absolute-mockup2" src={mockup2} width="80%" />
        </div>
        <div className="flex3">
          <span className="title-item">
            Ищите товар
            <br />
            по артикулу и названию
          </span>
          <span className="context-item">
            Для поиска достаточно ввести название
            <br />
            товара и артикул.
            <br />
            Далее наша система сама найдет
            
            нужный<br />товар и покажет его позицию в выдаче.
          </span>
        </div>
      </div>
      <div className="row-container ml-2">
        <div className="flex3">
          <div className="left-container mt-5">
            <span className="title-item">
              Или просто вставьте
              <br />
              ссылку
            </span>
            <span className="context-item">
              А если у вас есть ссылка на товар в выбранном<br /> маркетплейсе - вы можете просто вставить
              ее в<br />соответствующее поле в мобильном приложении.
            </span>
          </div>
        </div>
        <div className="flex2">
          <img className="elipse3" src={elipse} alt="elipse" height="30%" width="30%" />

          <img className="absolute-mockup3" src={mockup3} width="75%" />
        </div>
      </div>
      <div className="fm-container">
        <span className="fm-context">
          <span className="red">Быстрый</span> мониторинг
          <br />
          ваших товаров, который
          <br />
          всегда у вас
          <span className="blue"> в кармане</span>
        </span>
      </div>
      <div className="row-container mt-2">
        <div className="flex1">
          <div className="button-box">
            {(os == 'desktop' || os == 'ios') && (
              <img className="Button" src={iosButton} alt="ios Button" onClick={onClickIos} />
            )}
            {(os == 'android' || os == 'desktop') && (
              <>
                <img
                  className="Button"
                  src={rustoreButton}
                  alt="rustore Button"
                  onClick={onClickRuStore}
                />
                <img
                  className="Button"
                  src={googleButton}
                  alt="google Button"
                  onClick={onClickGoogle}
                />
                <img
                  className="Button"
                  src={huaweiButton}
                  alt="huawei Button"
                  onClick={onClickHuawei}
                />
                
              </>
            )}
            
          </div>
        </div>
        <div className="flex1">
          <img className="absolute-mockup4" src={mockup4} height="100%" width="70%" />
        </div>
      </div>
    </div>
  );
}

export default Desktop;
