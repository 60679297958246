import './App.css';
import MediaQuery from 'react-responsive';
import { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Linking } from 'react-native';
import iosButton from './img/ios-button.svg';
import googleButton from './img/google-button.svg';
import huaweiButton from './img/huawei-button.svg';
import rustoreButton from './img/rustore-button.svg';
import { ReactComponent as IPhone } from './img/iphone14.svg';

import logo from './img/logo.svg';

import Home from './Home';
import Politic from './Politic';
// import Offer from './Offer';
// import Agreement from './Agreement';

function App() {
  const uagent = navigator.userAgent.toLowerCase();

  const [OS, setOS] = useState('');
  const [config, setConfig] = useState({});

  const detectIphone = () => {
    console.log('uagent', uagent);
    if (/iphone|ipad/i.test(uagent)) {
      setOS('ios');
      return;
    }
    if (/android/i.test(uagent)) {
      setOS('android');
      return;
    }
    setOS('desktop');
  };

  const mount = useRef(null);
  useEffect(() => {
    if (!mount.current) {
      detectIphone();
      fetch('https://melper-back.yakovlev.studio/lending/static/policy/links')
        .then((res) => res.json())
        .then((res) => {
          // console.log('res:', res);
          setConfig(res);
        });
      mount.current = true;
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home config={config} />} />
        <Route path="/politic" element={<Politic link={config?.policy?.resources[0].link} />} />
        <Route path="/offer" element={<Politic link={config?.policy?.resources[1].link} />} />
        <Route path="/agreement" element={<Politic link={config?.policy?.resources[2].link} />} />
      </Routes>
    </Router>
  );
}

export default App;
