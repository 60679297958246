import '../App.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { Linking } from 'react-native';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import logo from '../img/logo.svg';
import tg from '../img/tg.svg';

function Footer({ config }) {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const urlTg = 'https://t.me/MelperHelperBot';

  return (
    <div>
      <div
        className="ml-2"
        style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}
      >
        <div className="doc-container align-start mt-3">
          <img src={logo} alt="logo" height="50" width="140" />
          <span className="about mt-1">Приложение для мониторинга товаров на маркетплейсах</span>
        </div>
        <div className="doc-container align-start mt-3">
          <span className="doc-title">Документы</span>
          <Link className="doc mt-1" to="/politic">
            Политика конфиденциальности
          </Link>
          <Link className="doc mt-1" to="/offer">
            Публичная оферта
          </Link>
          <Link className="doc mt-1" to="/agreement">
            Пользовательское соглашение
          </Link>
        </div>
        <div className="contact mt-3">
          <span className="doc-title">Контакты</span>

          <a
            className={`${isDesktop ? 'telegram-button' : 'tg-button-mobile'} mt-1`}
            href={urlTg}
            target="_blank"
            rel="noreferrer"
          >
            <div className="row-container width-tg">
              <img src={tg} height="90%" />
              <span className={`${isDesktop ? 'doc' : 'doc-mobile'} ml-1`}>
                Напишите нам в чат, оперативно
                <br />
                поможем решить любой вопрос
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="footer mt-2">
        <span className="footer-title">
          Сделано в{' '}
          <a
            className="footer-title weight500"
            href="https://yakovlev.studio/main/"
            target="_blank"
            rel="noreferrer"
          >
            Yakovlev.Studio
          </a>
        </span>
      </div>
    </div>
  );
}

export default Footer;
