import { useEffect, useRef, useContext } from 'react';
import { AnchorContext } from 'react-anchor-navigation';

const CustomSection = ({ children, ...attributes }) => {
  const { registerSection, unregisterSection, hash } = useContext(AnchorContext);
  const ref = useRef(null);
  // Logic to register/unregister component
  // when added/removed from DOM

  useEffect(() => {
    if (ref.current) {
      registerSection(ref.current);

      // Initialization of the component.
      // If mounted from a SPA without server-side rendering the hash will not
      // scroll at all, so do it manually
      if (attributes.id === hash.substr(1)) {
        window.addEventListener(
          'load',
          () => {
            console.log('Loaded!');
            // console.log('ref.current:', ref.current);
            setTimeout(() => ref.current.scrollIntoView(), 500);
          },
          false,
        );
        setTimeout(() => ref.current.scrollIntoView(), 1200);
      }
    }

    return () => {
      if (ref.current) {
        unregisterSection(ref.current);
      }
    };
  }, [hash]);

  return (
    <section {...attributes} ref={ref}>
      {children}
    </section>
  );
};

export default CustomSection;
